/* google font */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');


body {
  margin: 0;
  font-family: "Poppins";
  @apply bg-background;
}


/* button styles */
form {
  button {
  @apply 
    px-3 py-2 
    rounded-sm 
    flex justify-between items-center gap-2
    text-sm;
}
}
button:hover {
  @apply bg-opacity-90;
}
.btn-primary {
  @apply bg-primary text-white rounded-lg;
}
.btn-primary:hover {
  @apply text-primary bg-white;
}
.btn-secondary {
  @apply bg-gray-200 text-gray-900;
}

/* form styles */
form {
  @apply 
    py-4 px-7 
    bg-primary 
    bg-opacity-5 
    rounded-md 
    block 
    mx-auto 
    min-w-fit w-1/4;
}
form label {
  @apply block;
}
form input,
form textarea,
form select {
  @apply 
    block 
    mt-2 my-4 px-2 py-1 
    rounded-sm w-full;
}
form button {
  @apply block mx-auto;
}

/* feedback styles */
.error {
  @apply 
    border-2 
    border-red-500 
    bg-red-300
    text-red-800 
    py-1 px-2 
    rounded-sm
    block
    max-w-fit
    my-4 mx-auto;
}

/* card styles */
.card {
  @apply 
    bg-white 
    shadow-accent
    shadow-md 
    rounded-md 
    py-3 px-4 my-4 
    relative 
    overflow-hidden;
    h1{
     @apply text-accent
    }
}


